<template>
<div>
    <!-- 省市 选择 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" width="80%" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" >
                <el-form  :model="form_data" label-width="80px" style="margin-bottom: 12px;">

                    <el-form-item label="">
                        <el-checkbox :indeterminate="city_isIndeterminate" v-model="city_checkAll" @change="cityHandleCheckAllChange">全选</el-checkbox>

                        <el-checkbox-group v-model="form_data.send_city_array" size="mini" @change="cityHandleCheckedCitiesChange">
                            <el-checkbox v-for="item in cityList" :label="item" :key="item" ></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">确定</el-button>
                    </el-form-item>
                </el-form>

            </el-row>

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_item:{},//对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
            }

            this.title = this.prop_item.prov_name;
            this.prov_id = this.prop_item.prov_id;//省份id
            this.prov_name = this.prop_item.prov_name;//省份名
            this.form_data.send_city_array = this.prop_item.citys;//选中的城市列表 示例：["保定", "唐山"]

            //获取城市列表数据，根据省份id
            this.getProvCityList();//
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            title : "省市",
            prov_id : 0,//省份id
            prov_name : "",//省份名

            cityList:[],//城市列表

            city_checkAll: false,//城市的全选
            city_isIndeterminate: true,//城市的全选


            form_data:{
                send_city_array:[],//允许发送的省市的数组 示例：['保定','石家庄']
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {

    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.cityList = this.$options.data().cityList;//单个恢复初始化
        },

        //获取城市列表数据，根据省份id
        getProvCityList(){
            //请求接口
            API.ProvinceServlet({
                prov_id:this.prov_id,
                param: "getCitys"
            }).then((res) => {
                if (res.recode === 0) {
                    var city_array = res.city_array;
                    for(var j = 0;j<city_array.length;j++){
                        var a = city_array[j];
                        var city = a.city;
                        this.cityList.push(city);//省市列表数据-请求接口获取
                    }

                    //城市全选的初始化方法
                    this.cityAllInit();
                }
            });
        },

        //城市全选 触发的方法
        cityHandleCheckAllChange(val) {
            console.log("城市全选val:",val);
            this.form_data.send_city_array = val ? this.cityList : [];
            this.city_isIndeterminate = false;
        },
        //选择单个城市时，触发的方法
        cityHandleCheckedCitiesChange(value) {
            console.log("城市选择value:",value);
            let checkedCount = value.length;
            this.city_checkAll = checkedCount === this.form_data.send_city_array.length;
            this.city_isIndeterminate = checkedCount > 0 && checkedCount < this.cityList.length;
        },
        //城市全选的初始化方法
        cityAllInit() {
            console.log("城市全选的初始化方法");
            this.city_checkAll = this.cityList.length === this.form_data.send_city_array.length;
            this.city_isIndeterminate = this.form_data.send_city_array.length > 0 && this.form_data.send_city_array.length < this.cityList.length;
        },

        //提交
        onSubmit(){
            console.log("提交");
            //关闭
            this.back();
            //回调
            this.$emit("result",this.prov_id,this.prov_name,this.form_data.send_city_array);
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>